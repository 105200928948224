<template>
  <div>
    <v-container fluid>
      <div class="main">
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-space-between mb-3">
              <v-spacer/>

              <v-btn class="rounded-lg"
                     color="gifty"
                     dark
                     depressed
                     to="/quotas/create"
              >
                <v-icon left>mdi-plus</v-icon>
                Ajouter un quota
              </v-btn>
            </div>

            <v-card class="rounded-lg shadow">
              <v-overlay :value="overlay" absolute color="white" opacity="0.5">
                <v-progress-circular
                        color="primary"
                        indeterminate
                        size="50"
                ></v-progress-circular>
              </v-overlay>

              <v-card-title>
                <div>
                  <v-text-field
                          v-model="keyword"
                          append-icon="mdi-magnify"
                          clearable
                          dense
                          filled
                          hide-details
                          placeholder="Rechercher..."
                          rounded
                          single-line

                  />
                </div>
                <v-spacer/>

                <v-btn color="grey"
                       icon
                       @click="getQuotas">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>

              </v-card-title>

              <v-divider/>

              <v-card-text v-if="isLoading">
                <v-skeleton-loader type="table"/>
              </v-card-text>

              <div v-else>
                <v-card-text class="pa-0">
                  <v-simple-table v-if="quotas.length">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Statut</th>
                        <th>Nom</th>
                        <th>Type de calcul</th>
                        <th>Date de début</th>
                        <th>Date de fin</th>
                        <th>Services</th>
                        <th>Jours restants</th>
                        <th class="text-center">Tout le monde</th>
                        <th>Créé le</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(quota ,index) in quotas"
                          :key="index"
                      >
                        <td>
                          <v-switch v-model="quota.is_active"
                                    class="pa-0 ma-0"
                                    color="success"
                                    dense
                                    hide-details
                                    readonly
                                    @click="changeStatus(quota.id,index)"></v-switch>
                        </td>

                        <td class="text-no-wrap">
                          {{ quota.name }}
                        </td>

                        <td>
                          <div class="primary--text font-weight-medium text-no-wrap">
                            {{ quota.calculate_type === "month" ? "Par mois" : "Par année" }}
                          </div>
                        </td>

                        <td class="text-no-wrap">
                          <v-icon :style="{marginTop:'-3px'}"
                                  color="gifty"
                                  small>mdi-clock-in
                          </v-icon>
                          {{ quota.start_date_formated }}
                        </td>

                        <td class="text-no-wrap">
                          <v-icon :style="{marginTop:'-3px'}"
                                  color="gifty"
                                  small>mdi-clock-out
                          </v-icon>
                          {{ quota.end_date_formated }}
                        </td>

                        <td>
                          <v-chip color="info"
                                  dark
                                  small
                                  @click="$refs.servicesDialog.open(quota.services)">
                            <v-icon left small>mdi-layers-triple</v-icon>
                            {{ quota.services.length }}
                          </v-chip>
                        </td>

                        <td>
                          <v-chip :color="quota.status.color" dark small> {{ quota.status.value }}</v-chip>
                        </td>

                        <td class="text-center">
                          <v-icon v-if="quota.assignment_type === 'all'" color="success">
                            mdi-check-circle
                          </v-icon>
                          <v-icon v-else color="success">mdi-circle-outline</v-icon>
                        </td>

                        <td class="text-no-wrap">
                          <v-icon :style="{marginTop:'-3px'}"
                                  color="gifty"
                                  small>mdi-clock-outline
                          </v-icon>
                          {{ quota.created_at }}
                        </td>

                        <td>

                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon
                                     v-bind="attrs"
                                     v-on="on"
                              >
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list dense>

                              <v-list-item @click="$refs.prolongDialog.open(quota)">
                                <v-list-item-icon>
                                  <v-icon>mdi-clock-start</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Prolonger</v-list-item-title>
                              </v-list-item>

                              <v-list-item :to="'/quotas/'+quota.id+'/update'">
                                <v-list-item-icon>
                                  <v-icon>mdi-pencil-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Modifier</v-list-item-title>
                              </v-list-item>

                              <v-list-item @click="remove(quota.id)">
                                <v-list-item-icon>
                                  <v-icon>mdi-delete-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Supprimer</v-list-item-title>
                              </v-list-item>

                            </v-list>
                          </v-menu>

                        </td>

                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div v-else>
                    <NoResults @reload="getQuotas"/>
                  </div>
                </v-card-text>

                <v-divider v-if="total > 10"/>

                <v-card-actions v-if="total > 10">

                  <div :style="{width: '120px'}">
                    <v-select v-model="perPage"
                              :items="[10,20,30,50,100]"
                              dense
                              hide-details
                              label="Ligne par page"
                              outlined></v-select>
                  </div>

                  <v-spacer/>

                  <v-pagination v-model="pagination.current"
                                :length="pagination.total"
                                circle
                                total-visible="8"
                                @input="getQuotas"></v-pagination>

                </v-card-actions>

              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <ServicesDialog ref="servicesDialog"/>
      <ProlongDialog ref="prolongDialog" @refresh="getQuotas"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import ServicesDialog from "./components/ServicesDialog.vue";
import ProlongDialog from "./components/ProlongDialog.vue";

export default {
   components: {ProlongDialog, NoResults, ServicesDialog},
   data() {
      return {
         keyword: '',
         id: '',
         overlay: false,
         isLoading: false,
         quotas: [],

         total: 0,
         perPage: 10,
         pagination: {
            current: 1,
            total: 0
         },
      };
   },
   methods: {
      getQuotas() {
         this.isLoading = true
         HTTP.get('/v1/quotas?page=' + this.pagination.current, {
            params: {
               perPage: this.perPage,
               keyword: this.keyword
            }
         }).then(res => {
            this.pagination.current = res.data.data.current_page;
            this.pagination.total = res.data.data.last_page;
            this.total = res.data.data.total;

            this.quotas = res.data.data.data

            this.isLoading = false
         }).catch(err => {
            this.isLoading = false
            console.log(err)
         })
      },
      remove(id) {
         this.id = id;
         this.$confirm_dialog = true;
      },
      deleteItem() {
         this.overlay = true;
         HTTP.post("/v1/quotas/delete", {
            id: this.id,
         }).then(() => {
            this.overlay = false;
            let i = this.quotas.findIndex((el) => el.id === this.id);
            this.$delete(this.quotas, i);
            this.id = null;
            this.$successMessage = "Cet élément a été supprimé avec succès.";
         }).catch((err) => {
            this.overlay = false;
            console.log(err);
         });
      },
      changeStatus(id, index) {
         this.overlay = true;
         HTTP.post("/v1/quotas/change-status", {
            id: id,
         }).then(() => {
            this.overlay = false;
            this.quotas[index].is_active = !this.quotas[index].is_active
            this.$successMessage = "Cet élément a été modifié avec succès.";
         }).catch((err) => {
            this.overlay = false;
            console.log(err);
         });
      },
   },
   created() {
      this.getQuotas();
   },
   watch: {
      $is_confirm: function (el) {
         if (el) {
            this.deleteItem();
            this.$is_confirm = false;
         }
      },
      perPage() {
         this.pagination.current = 1
         this.getQuotas();
      },
      keyword() {
         this.getQuotas();
      },
   }
};
</script>

<style scoped></style>
